import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const QueryContext = createContext();
export const ModeContext = createContext();
export const TabContext = createContext();

export const QueryContextProvider = ({ children }) => {
  const [queryItems, setQueryItems] = useState({
    outstanding_wo: '',
    current_wo: ''
  });
  return (
    <QueryContext.Provider value={[queryItems, setQueryItems]}>{children}</QueryContext.Provider>
  );
};

QueryContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export const ModeContextProvider = ({ children }) => {
  const [mode, setMode] = useState('current_wo');
  return <ModeContext.Provider value={[mode, setMode]}>{children}</ModeContext.Provider>;
};

ModeContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export const TabContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('');
  return <TabContext.Provider value={[activeTab, setActiveTab]}>{children}</TabContext.Provider>;
};

TabContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
