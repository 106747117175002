import MockAdapter from 'axios-mock-adapter';
// import { invoiceManagementData, detailInvoice } from './demo_data';

const mockAxios = ax => {
  if (process?.env?.REACT_APP_IS_LOCAL) {
    // eslint-disable-next-line no-console
    console.log('THIS IS LOCAL BUILD!!!!');
    // eslint-disable-next-line no-console
    console.log('SOME REQUESTS WILL BE MOCKED!');
    const mock = new MockAdapter(ax, {
      onNoMatch: 'passthrough',
      delayResponse: 1000
    });
    // mock
    //   .onGet(new RegExp(`https://local-front.proteanapp.com/api/v3/invoice/get_invoice/*`))
    //   .reply(200, detailInvoice);
    // mock
    //   .onGet(new RegExp(`https://local-front.proteanapp.com/api/v3/invoices?/*`))
    //   .reply(200, invoiceManagementData);
    // // eslint-disable-next-line no-console
    console.log(mock);
  }
};

export default mockAxios;
