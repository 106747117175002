// import axios from 'axios';
import axios from './axiosInstance';
import config from '../_config/config';

// axios.defaults.withCredentials = true;

export const getChart = async (cancelTokenSrc, viewType, client) => {
  try {
    const res = await axios.get(
      `${config.api.api_url + config.api.charts.line_charts}/months/5/${viewType}?client=${client}`,
      {
        cancelToken: cancelTokenSrc.token
      }
    );
    return res.data;
  } catch {
    return null;
  }
};

export const getDeficiencies = async (cancelTokenSrc, view, client) => {
  const req = await axios.get(
    `${config.api.api_url}${config.api.charts.deficiencies_chart}${view}?client=${client}`,
    {
      cancelToken: cancelTokenSrc.token
    }
  );
  // req.data = {
  //   current_month: 0,
  //   previous_month: 0,
  //   layer_type: 'client'
  // };
  return req.data;
};

export const getChartWithData = async (type, payload) => {
  const req = await axios.post(config.api.api_url + config.api.charts.charts + type, payload);
  return req.data;
};

const ChartActions = {
  getChart,
  getChartWithData
};

export default ChartActions;
