export default {
  api: {
    api_url: process.env.REACT_APP_API_ENDPOINT || '',
    api_version: '/api/version',
    filtersAnalytics: '/api/filters/analytics',
    monitoring: {
      list: '/api/v1/background_tasks',
      detail: id => `/api/v1/background_tasks/${id}`,
      rerun: id => `/api/v1/background_tasks/${id}/rerun`,
      stats: type => `/api/v1/background_tasks/stats/${type}`
    },
    management: {
      update_caches: id => `/api/v3/management/update_group_association_caches/${id}`
    },
    notifications: {
      readNotification: id => `/api/v2/notifications/${id}/read`,
      notifications: '/api/v2/notifications',
      configs: '/api/v2/notifications/configs',
      saveConfig: '/api/v2/notifications/configs/save_config',
      mark_all: '/api/v2/notifications/mark_all_as_read/'
    },
    analytics: {
      analytics: '/api/analytics',
      analytics_sheet_names: '/api/analytics/sheet_names',
      site_analytics: siteId => `/api/v2/analytics/site/${siteId}`
    },
    atg: {
      send_command: '/atg/command',
      save_command: '/atg/save_command',
      saved_commands: '/atg/saved_commands',
      commands: '/api/v2/atg/commands',
      execute_commands: '/api/v2/atg/execute-commands',
      site_responses: siteId => `/api/v2/atg/sites/${siteId}/responses`,
      sites: '/api/v2/atg/sites',
      rerun_command: responseId => `/api/v2/atg/responses/${responseId}/rerun`,
      acknowledge: (siteId, alarmId) => `/api/v2/atg/sites/${siteId}/alarms/${alarmId}/acknowledge`,
      site_alarms: siteId => `/api/v2/atg/sites/${siteId}/alarms`,
      available_commands: '/api/v2/atg/available-commands',
      equipment: siteId => `/api/v2/atg/site/${siteId}/equipment`,
      configuration: siteId => `/api/v2/atg/site/${siteId}/configuration`,
      display_pdf: responseId => `/api/v2/atg/responses/${responseId}/display_pdf`,
      download_pdf: responseId => `/api/v2/atg/responses/${responseId}/download_pdf`,
      alarms: '/api/v2/atg/alarms',
      alarmsV3: '/api/v3/atg/alarms',
      alarmsV3_widget: '/api/v3/atg/alarms/widget',
      alarmsV3_site: id => `/api/v3/atg/sites/${id}/alarms`,

      alarms_widget: '/api/v2/atg/alarms/widget',
      configuration_v2: siteId => `/api/v3/atg/site/${siteId}/configuration`,
      release_detection: siteId => `/api/v2/atg/site/${siteId}/release-detection`,
      release_detectionV3: siteId => `/api/v3/atg/sites/${siteId}/release_detection`,
      populate_release_detection: '/api/v2/atg/commands/release-detection',
      populate_equipment_overview: '/api/v2/atg/commands/equipment-overview',
      populate_configuration: '/api/v2/atg/commands/configuration',
      release_detection_sites: '/api/v2/atg/sites/release_detection',
      release_detection_sitesV3: '/api/v3/atg/sites/release_detection',
      setup_commands_categories: siteId => `/api/v2/atg/sites/${siteId}/setup-commands/categories`,
      setup_commands_by_category: (siteId, category) =>
        // eslint-disable-next-line
        `/api/v2/atg/sites/${siteId}/setup-commands${category ? '?category=' + category : ''}`,
      setup_command_response: responseId => `/api/v2/atg/response/${responseId}/status`,
      facility_setup_command: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}`,
      facility_setup_command_populate: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}/populate`,
      facility_setup_command_population_data: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}/populated-data`,
      facility_setup_command_execute: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/setup-commands/${commandId}/execute`,
      setup_center_sites: '/api/v2/atg/sites/setup_center',
      connection_center_sites: '/api/v2/atg/sites/connection_center',
      connection_center_sites_export: type => `/api/v2/atg/sites/connection_center/export-${type}`,
      diagnose_connection: '/api/v2/atg/connection_center/diagnose',
      diagnose_result: sessionId =>
        `/api/v2/atg/connection_center/diagnostic_session/responses/${sessionId}`,
      download_diagnose_result: sessionId =>
        `/api/v2/atg/connection_center/diagnostic_session/results/${sessionId}`,
      generate_report: id => `/api/atg/responses/${id}/generate_report`,
      check_report_availability: id => `/api/atg/responses/${id}/check_atg_report`,
      inventory_tank_detail: id => `/api/v1/inventory_management/${id}/details`,
      inventory_list: type => `/api/v1/inventory_management/${type}`,
      functional_command_start: siteId => `/api/v2/atg/sites/${siteId}/function/execute`,
      functional_command_status: (siteId, commandId) =>
        `/api/v2/atg/sites/${siteId}/function/${commandId}/info`,
      execute_custom_command: '/api/v2/atg/sites/human-commands/execute/',
      check_custom_command_status: id => `/api/v2/atg/human_response/${id}/status`,
      export_human: '/api/v2/atg/sites/human-commands/export'
    },
    invoice_management: {
      table: () => `/api/v3/invoices`,
      sub_table: id => `/api/v2/workorder/detail_page/issues_group_items/${id}`,
      invoice_detail: id => `/api/v3/invoice/get_invoice/${id}`,
      invoice_update: id => `/api/v3/invoice/update_invoice/${id}`,
      change_billing_code: (id, type) => `/api/v3/update_gl_code/${type}/${id}`
    },
    inventory: {
      getDeliveryRequest: (tankId, requestId) =>
        `/api/v1/inventory_management/${tankId}/delivery_request/${requestId || ''}`,
      createDeliveryRequest: tankId => `/api/v1/inventory_management/${tankId}/delivery_request`,
      updateDeliveryRequest: (tankId, requestId) =>
        `/api/v1/inventory_management/${tankId}/delivery_request/${requestId}`,
      deliveryRequestEmail: (tankId, requestId) =>
        `/api/v1/inventory_management/${tankId}/delivery_request/${requestId}/dispatch_email`
    },
    auth: {
      csrf_login: '/auth/csrf-login',
      loginV2: '/api/v2/login',
      me: '/api/v2/users/me',
      login: '/auth/login',
      sso_verify: (ssoId, token) => `/auth/sso/${ssoId}/${token}`,
      logout: '/api/v2/logout',
      refresh_token: '/api/v2/refresh-token',
      auth_test: '/auth/test',
      csrf_reset_password: '/auth/csrf-reset',
      reset_password: token => `/api/v2/auth/forgot_password/${token}`,
      forgot_password: '/api/v2/auth/forgot_password',
      send_code: '/api/v2/login/submit-code',
      resend_code: '/api/v2/login/resend-code'
    },
    categories: {
      categories: '/api/v2/categories'
    },
    charts: {
      // calendar: level => `/api/v2/calendar_widget/${level}`,
      calendar: (level, dateRange, site, client) =>
        `/api/v3/calendar/widget?layer-type=${level}&date-range=${dateRange}${
          site ? `&site=${site}` : ''
        }${client ? `&client=${client}` : ''}`,
      calendar_export: (level, client) => `api/v2/calendar_widget_excel/${level}?client=${client}`,
      calendar_day_export: (start, end, level) =>
        `api/v2/calendar_one_day_csv/${level}?start_day=${start}&end_day=${end}`,
      charts: '/api/charts',
      line_charts: '/api/v2/line_charts',
      exceptions_by_site: '/api/charts/ExceptionsBySite/months/6',
      exceptions_by_category: '/api/charts/ExceptionsByCategory/months/6',
      exceptions_by_state: '/api/charts/ExceptionsByState/months/6',
      recurring_exceptions: '/api/charts/RecurringExceptions/months/6',
      repeating_exceptions: '/api/charts/RepeatingExceptions/months/12',
      deficiencies_chart: '/api/v2/deficiencies_chart/',
      alarmsIssues: (type, client) =>
        `/api/v2/dashboard/get_alarms_and_issues/${type}?client=${client}`,
      inspectionStats: (type, client) =>
        `/api/v2/dashboard/inspection_stats/${type}?client=${client}`
    },
    dashboard: {
      clients: '/api/v2/dashboard/widgets/clients',
      client_config: id => `/api/v2/dashboard/widgets/${id}`
    },
    clients: {
      clients: '/api/clients'
    },
    content: {
      pp_agreement: '/privacy-policy/$pp_id',
      tos_agreement: '/terms-of-service/$tos_id'
    },
    files: {
      root: '/api/v2/files',
      move: '/files/move',
      search: '/api/v2/files/search',
      // TODO: remove after testing new solution
      // file_path: fileId => `/files/id/${fileId}/`,
      file_path: fileId => `/api/v2/files/${fileId}`,
      share_file: fileId => `/api/v2/files/${fileId}/shares`,
      stop_share: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      update_shared: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      share_info: id => `/api/v2/files/${id}/shares`,
      shared_file_path: fileId => `/files/share/${fileId}/`,
      shared_sub_file_path: (fileId, subId) => `/files/share/${fileId}/${subId}`,
      download_path: fileId => `/files/download_queue/${fileId || ''}`,
      // download_shared_path: fileId => `/files/download_queue/${fileId}`,
      facility_files: siteId => `/api/v3/files/site/${siteId}/`,
      upload: '/files/upload',
      create_folder: '/files/new_directory',
      append_download_queue: '/files/append_download_queue',
      pop_download_queue: '/files/pop_download_queue',
      clear_download_queue: '/files/clear_download_queue',
      photo_folder: siteId => `/api/files/site/${siteId}/Photos`,
      display_file: fileId => `/files/id/${fileId}/?display=true`,
      set_ariel: (siteId, fileId) => `/api/files/ariel_photo/${siteId}/${fileId}/`,
      set_sitemap: (siteId, fileId) => `/api/files/sitemap_photo/${siteId}/${fileId}/`,
      get_url: fileId => `/files/external_link/${fileId}`
    },
    filesV3: {
      root: '/api/v3/files',
      move: id => `/api/v3/files/move/${id}`,
      // move: `/api/v3/files/move`,
      searchFolder: id => `/api/v3/files/${id}/search`,
      search: '/api/v3/files/search',
      // TODO: remove after testing new solution
      // file_path: fileId => `/files/id/${fileId}/`,
      file_path: fileId => `/api/v3/files/${fileId}`,
      share_file: fileId => `/api/v2/files/${fileId}/shares`,
      stop_share: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      update_shared: (fileId, shareId) => `/api/v2/files/${fileId}/shares/${shareId}`,
      share_info: id => `/api/v2/files/${id}/shares`,
      shared_file_path: fileId => `/files/share/${fileId}/`,
      shared_sub_file_path: (fileId, subId) => `/files/share/${fileId}/${subId}`,
      download_path: fileId => `/files/download_queue/${fileId || ''}`,
      // download_shared_path: fileId => `/files/download_queue/${fileId}`,
      facility_files: siteId => `/api/files/site/${siteId}/`,
      upload: id => `/api/v3/files/${id}/upload_files`,
      // create_folder: '/files/new_directory',
      create_folder: id => `/api/v3/files/${id}/create_folder`,
      append_download_queue: '/files/append_download_queue',
      pop_download_queue: '/files/pop_download_queue',
      clear_download_queue: '/files/clear_download_queue',
      photo_folder: siteId => `/api/files/site/${siteId}/Photos`,
      display_file: fileId => `/files/id/${fileId}/?display=true`,
      set_ariel: (siteId, fileId) => `/api/files/ariel_photo/${siteId}/${fileId}/`,
      set_sitemap: (siteId, fileId) => `/api/files/sitemap_photo/${siteId}/${fileId}/`,
      get_url: fileId => `/files/external_link/${fileId}`,
      get_shared_url: (uuid, id) => `/files/share/${uuid}/${id}?action=external-link`,
      rename: id => `/api/v3/files/${id}`
    },
    filters: {
      permit_tracking_agencies: '/api/filters/permit_tracking/agencies',
      filter_sites: `/api/v2/filters/sites`
    },
    inspection_types: {
      inspection_types: '/api/v2/inspection_types'
    },
    issues: {
      issue_atg_history: id => `/api/v3/atg/log_history/${id}`,
      create_issue: siteId => `/api/issues/site/${siteId}/create`,
      site_issues: siteId => `/api/issues/site/${siteId}`,
      add_comment: issue => `/issues/${issue}/submit_comment`,
      facilityIssues: siteId => `/api/v2/issues/site/${siteId}`,
      issues_detail: id => `/api/v2/vi_issues/${id}/details`,
      issues_set_due_date: id => `/api/v2/vi_issues/set_due_date/${id}`,
      issues_change_status: id => `/api/v2/vi_issues/change_status/${id}`,
      issues_change_user: id => `/api/v2/vi_issues/assign_user/${id}`,
      issues_change_assign: (id, type) => `/api/v2/vi_issues/assigned_to/${type}/${id}`,
      issues_occurrences: id => `/api/v2/vi_issues/${id}/previous_occurrences`,
      issue_change_log: id => `/api/v2/vi_issues/${id}/change_logs`,
      issue_add_comment: id => `/api/v2/vi_issues/create_comment/${id}`,
      issue_comments: id => `/api/v2/vi_issues/${id}/comments`,
      issue_related_files: id => `/api/v2/vi_issues/${id}/related_files`,
      assign_to_users: id => `/api/v2/vi_issues/assigned_to/assignment/${id}`,
      dispatch_to_users: id => `/api/v2/vi_issues/assigned_to/dispatch/${id}`,
      mentions: id => `/api/v2/vi_issues/users/mentions/${id}`
    },
    permits: {
      add_comment: permit => `/permits/${permit}/submit_comment`,
      facilityPermits: siteId => `/api/v2/sites/${siteId}/permits`,
      addPermit: `/api/v2/permits/`
    },
    profile: {
      filter_sites: '/api/site_profile_sites',
      site_profile: siteId => `/api/site/${siteId}/profile`,
      site_compliance_widgets: (siteId, widget = '') =>
        `/api/refresh_compliance_widgets/${siteId}/${widget}`
    },
    notes: {
      update_note: id => `/api/site/${id}/profile/note`
    },
    reports: {
      site_reports: siteId => `/api/v3/reports/site/${siteId}`,
      cp_report: '/api/cp_report',
      display_pdf: fileID => `/api/v2/reports/${fileID}/display_pdf`,
      download_pdf: fileID => `/api/v2/reports/${fileID}/download_pdf`,
      recap_report: '/api/recap_report',
      hot_topic_report: (year, month) => `/api/ht_report/year/${year}/month/${month}`,
      remedy_report_monthly: (year, month, client) =>
        `/api/remedy_report/year/${year}/month/${month}/client/${client}`,
      remedy_report_weekly: (year, week, client) =>
        `/api/remedy_report/year/${year}/week/${week}/client/${client}`,
      widget_report: (type, view, client) => `/api/widget/${type}/${view}?client=${client}`,
      ht_report: clientId => `/api/v2/ht_report/${clientId}`,
      remedy_report: `/api/v2/remedy_report`,
      walmart_report: `/api/v2/walmart_scorecard`,
      daily_report: `/api/v2/reports/daily_overview`,
      periodic_report: `/api/v2/reports/periodic_report`,
      dispatch_data: reportId => `/api/v2/reports/${reportId}/data`,
      dispatch_email: reportId => `/api/v2/reports/${reportId}/dispatch_email`,
      can_dispatch: reportId => `/api/v2/reports/${reportId}/can_dispatch`
    },
    shopper: {
      brands: '/api/brands',
      inspection_score: '/api/inspection/score/overview',
      inspection_score_edit: inspection => `/api/inspection/score/edit/${inspection}`,
      inspection_scores: '/api/inspection/score/list',
      score_by_brand_per_user: dayDiff => `/api/inspection/score/by_brand_per_user/limit/${dayDiff}`
    },
    states: {
      states: '/api/states'
    },
    question_sets: {
      inspection_types: siteId => `/api/question_sets/site/${siteId}`,
      prompts: (siteId, inspectionType) =>
        `/api/question_sets/site/${siteId}/inspection_type/${inspectionType}`
    },
    inspections: {
      dashboard: id => `/api/v2/all_inspections/historical_dashboard/${id}`,
      dashboardV3: id => `/api/v3/all_inspections/historical_dashboard/${id}`,
      photo: id => `/api/photo/${id}`,
      setStatus: '/api/v2/all_inspections/report_reviews/',
      changeStatus: id => `/api/v2/all_inspections/report_reviews/${id}`,
      logReport: ({ viewType, sectionType, sendTo, fileType }) =>
        `/api/v2/all_inspections/export_change_logs/${viewType}/${sectionType}/${sendTo}/${fileType}`,
      siteData: id => `/api/v2/site/${id}/profile_mobile`
    },
    tests: {
      facilityTests: siteId => `/api/v2/sites/${siteId}/compliance_testing`,
      addTest: `/api/v2/compliance_testing/`,
      changeStatus: testId => `/api/v2/compliance_testing/${testId}/complete_test/`,
      tests: `/api/v2/sites/compliance_testing/grouped_view/`,
      site_test: id => `/api/v2/sites/compliance_testing/${id}/`,
      fail_pass: '/api/v2/compliance_testing/complete_test',
      set_date: '/api/v2/compliance_testing/set_schedule_date',
      next_planned_date_calculation:
        '/api/v2/sites/compliance_testing/next_planned_date_calculation',
      create_test: '/api/v2/compliance_testing',
      related_tests: '/api/v2/compliance_testing/related/',
      sites: '/api/v2/filters/sites?table_type=tests',
      site_tests: id => `/api/v2/sites/compliance_testing/${id}`,
      upload_site_tests: `/api/v2/sites/compliance_testing/test_reports/sites`,
      upload_report: '/api/v2/sites/compliance_testing/test_reports',
      review_test: id => `/api/v2/sites/compliance_testing/report_review/${id}`,
      review_add_test: id => `/api/v2/sites/compliance_testing/add_to_test_report/${id}`,
      test_details: id => `/api/v2/sites/compliance_testing/details/${id}`,
      previous_tests: id => `/api/v2/sites/compliance_testing/previous/${id}`,
      comments: id => `/api/v2/sites/compliance_testing/comments/${id}`,
      mentions: id => `/api/v2/sites/compliance_testing/mentions/${id}`
    },
    work_orders: {
      details: id => `/api/v2/workorder/detail_page/wo_details/${id}`,
      issuesGroups: id => `/api/v2/workorder/detail_page/wo_details/${id}/issue_groups`,
      newIssuesGroup: (site, wo) => `/api/v2/${wo}/${site}/issue_groups`,
      wo_line_items: id => `/api/v2/workorder/detail_page/line_items/${id}`,
      issue_group_items: id => `/api/v2/workorder/detail_page/issues_group_items/${id}`,
      assign_to_users: id => `/api/v2/issues_group/users/assigned_to/${id}`,
      dispatch_to_users: id => `/api/v2/issues_group/users/dispatch_to/${id}`,
      logs: id => `/api/v2/workorder/detail_page/change_logs/${id}`,
      create_group: '/api/v2/issues_group',
      edit_group: id => `/api/v2/issues_group/${id}`,
      delete_group: id => `/api/v2/issues_group/delete/${id}`,
      reopen: id => `/api/v2/workorder/detail_page/reopen_wo/${id}`,
      issuesTypes: '/api/v2/workorder/line_item_types',
      getIssuesForGroup: id => `/api/v2/issues_group/${id}`,
      predefinedName: id => `/api/v2/issues_group/${id}/next_name`
    },
    groups: {
      add_issue_to_group: (issueId, groupId) =>
        `/api/v2/issues/${issueId}/issues_group/${groupId}/`,
      detail: id => `/api/v2/issues_group/${id}/details`,
      set_due_date: id => `/api/v2/issues_group/set_due_date/${id}`,
      assign_user: id => `/api/v2/issues_group/assign_user/${id}`,
      assign_list: id => `/api/v2/issues_group/users/assigned_to/${id}/`,
      dispatch_list: id => `/api/v2/issues_group/users/dispatch_to/${id}/`,
      change_status: id => `/api/v2/issues_group/change_status/${id}`,
      line_items: id => `/api/v2/issues_group/${id}/line_items`,
      change_logs: id => `/api/v2/issues_group/${id}/change_logs`,
      add_comment: id => `/api/v2/issues_group/create_comment/${id}`,
      comments: id => `/api/v2/issues_group/${id}/comments`,
      related_files: id => `/api/v2/issues_group/${id}/related_files`,
      add_related_file: id => `/api/v2/issues_group/${id}/related_files`,
      mentions: id => `/api/v2/issues_group/users/mentions/${id}`,
      complete: `/api/v2/issues_group/submit`
    },
    score_card: {
      clients: '/api/v2/clients/allowed/scorecard',
      report: '/api/v2/scorecard/generate',
      deficiency: {
        deficienciesPerMonth: '/api/v2/scorecard/deficiency-trends/deficiencies-per-month',
        byCategory: '/api/v2/scorecard/deficiency-trends/deficiencies-by-category',
        repeatingIssues: '/api/v2/scorecard/deficiency-trends/repeating-issues',
        recurringIssues: '/api/v2/scorecard/deficiency-trends/recurring-issues'
      },
      performance: {
        completion: '/api/v2/scorecard/performance-trends/completion',
        accuracy: '/api/v2/scorecard/performance-trends/accuracy',
        timeOnSite: '/api/v2/scorecard/performance-trends/time-on-site',
        monthlyEvents: '/api/v2/scorecard/performance-trends/bi-monthly-events'
      },
      specifics: {
        topDeficiencies: '/api/v2/scorecard/deficiency-specifics/top-deficiencies',
        leastDeficiencies: '/api/v2/scorecard/deficiency-specifics/least-deficiencies',
        mostCompliance: '/api/v2/scorecard/deficiency-specifics/top-sites-with-deficiencies',
        leastCompliance: '/api/v2/scorecard/deficiency-specifics/least-sites-with-deficiencies'
      }
    },
    deficienciesAnalitycs: {
      deficienciesAnalitycs: '/api/v3/quick-dispatch',
      update: id => `/api/v3/quick-dispatch/${id}`,
      updateMany: `/api/v3/quick-dispatch/issues/update`,
      photoProxy: id => `/api/v3/photos/${id}/proxy`,
      email: id => `/api/v3/quick-dispatch/${id}/dispatch-data`,
      email_multiple: `/api/v3/quick-dispatch/multiple_issue/dispatch-data`,
      subDetails: id => `/api/v2/line_items/${id}/sub_details`
    },
    vendors: {
      vendorsList: '/api/v3/vendors',
      // createVendor: '/api/v3/vendors',
      createEditVendor: (id = '') => `/api/v3/vendors/${id}`,
      assignmentsList: '/api/v3/vendors-assignments',
      createUpdateAssignment: (id = '') => `/api/v3/vendors-assignments/${id}`
    }
  },
  ws: {
    ws_url: process.env.REACT_APP_API_ENDPOINT || ''
  }
};
