import AX from 'axios';
import { getTokens, clearLocalStorage, setTokens, setUser } from '../helpers/localStorage';
import apiConfig from '../_config/config';
import mockAxios from '../helpers/mockAxios';

const axios = AX.create();

// axios.defaults.withCredentials = true;
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  isRefreshing = false;
  failedQueue = [];
};

const getNewToken = async () => {
  const tokens = getTokens();
  const response = await AX.post(apiConfig.api.api_url + apiConfig.api.auth.refresh_token, {
    refresh_token: tokens ? tokens.refresh_token : ''
  });
  setTokens(response.data);
  return response.data;
};

const setBannerState = data => {
  const currentText = localStorage.getItem('banner_text');
  const currentIsEnabled = localStorage.getItem('banner');
  let newIsEnabled = data.enabled;
  const newText = data.comment;
  if (newText === currentText) {
    newIsEnabled = newIsEnabled ? currentIsEnabled : false;
  }
  localStorage.setItem('banner', newIsEnabled);
  localStorage.setItem('banner_text', newText);
};

const updateUser = async () => {
  try {
    const response = await axios.get(apiConfig.api.api_url + apiConfig.api.auth.me);
    setUser(response.data);
    setBannerState(response.data.maintenance_data);
  } catch (e) {
    clearLocalStorage();
  }
};

axios.interceptors.request.use(
  async config => {
    const newConf = { ...config };
    const tokens = getTokens();
    newConf.headers.Authorization = `Bearer ${tokens ? tokens.access_token : ''}`;
    return newConf;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const origConf = error.config;
    const prevPage = window.location.pathname;
    if (error?.response?.status === 401) {
      if (!origConf.isNextTry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(accessToken => {
              axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
              return axios(origConf);
            })
            .catch(err => {
              return err;
            });
        }
        origConf.isNextTry = true;
        isRefreshing = true;
        try {
          const { access_token: accessToken } = await getNewToken();
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          updateUser();
          processQueue(null, accessToken);
          origConf.isNextTry = false;
          isRefreshing = false;
          return axios(origConf);
        } catch (e) {
          clearLocalStorage();
          window.location.href = `/login?q=${prevPage}`;
        }
      }
      clearLocalStorage();
      window.location.href = `/login?q=${prevPage}`;
    }
    return Promise.reject(error);
  }
);

mockAxios(axios);

export default axios;
