/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import { createContext, useState } from 'react';

const initialContext = {
  isOpen: false,
  isPinned: false
};

const SidebarContext = createContext([{}, () => {}]);

const SidebarProvider = props => {
  const [state, setState] = useState(initialContext);
  return (
    <SidebarContext.Provider value={[state, setState]}>{props.children}</SidebarContext.Provider>
  );
};

export { SidebarContext, SidebarProvider };
