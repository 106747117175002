// import axios from 'axios';
import axios from './axiosInstance';
import config from '../_config/config';

const apiURL = path => `${config.api.api_url}${path}`;

const makeRequest = async (path, method = 'get', data) => {
  const url = apiURL(path);
  let resp = Promise;
  try {
    if (method === 'get') {
      resp = await axios(url);
    } else {
      resp = await axios[method](url, data);
    }
  } catch (e) {
    resp = e.response;
  }
  return resp?.data;
};

export const getFileUrl = fileId => apiURL(config.api.files.file_path(fileId));
export const getSharedFileUrl = (fileId, subId) =>
  apiURL(`${config.api.files.shared_sub_file_path(fileId, subId)}?action=download`);
export const getDownloadUrl = fileId => apiURL(config.api.files.download_path(fileId));
export const getDownloadZipUrl = (fileId, subId, idsArray) => {
  let path = null;
  if (fileId && !subId) {
    path = apiURL(
      `${config.api.files.shared_file_path(fileId)}?action=download_zip&items=${idsArray}`
    );
  } else if (fileId && subId) {
    path = apiURL(
      `${config.api.files.shared_sub_file_path(
        fileId,
        subId
      )}?action=download_zip&items=${idsArray}`
    );
  }
  return path;
};

export const getFiles = fileId => {
  let path = config.api.files.root;
  if (fileId) {
    path = config.api.files.file_path(fileId);
  }
  return makeRequest(path);
};

export const getFilesV3 = (fileId, size) => {
  let path = config.api.filesV3.root;
  if (fileId) {
    path = config.api.filesV3.file_path(fileId);
  }
  return makeRequest(`${path}?size=${size || 25}`);
};

export const getFilesPage = data => {
  const base = data.id ? config.api.filesV3.file_path(data.id) : config.api.filesV3.root;
  return makeRequest(`${base}?page=${data.page}&size=${data.size}`);
};

export const getSharedFiles = (fileId, subId) => {
  let path = config.api.files.root;
  if (fileId && !subId) {
    path = `${config.api.files.shared_file_path(fileId)}?action=get`;
  } else if (fileId && subId) {
    path = `${config.api.files.shared_sub_file_path(fileId, subId)}?action=get`;
  }
  return makeRequest(path);
};

export const searchGlobalV3 = ({ search, page, size = 25, fileId }) => {
  const basePath = fileId ? config.api.filesV3.searchFolder(fileId) : config.api.filesV3.search;
  const path = `${basePath}?search=${search}&page=${page}&size=${size}`;
  return makeRequest(path);
};

export const moveFiles = (source, destination) => {
  return makeRequest(config.api.files.move, 'post', { source, destination });
};

export const moveFilesV3 = (source, destination) => {
  return makeRequest(config.api.filesV3.move(source), 'patch', { destination_id: destination });
};

// export const searchFiles = query => {
//   return makeRequest(config.api.files.search, 'post', { search: query });
// };

// export const searchFiles2 = query => {
//   return makeRequest(config.api.files.search2, 'post', { search: query });
// };

export const appendDownloadQueue = (root, files) => {
  const payload = new FormData();
  payload.append('root', root);
  files.forEach(file => {
    payload.append('files[]', file);
  });

  return makeRequest(config.api.files.append_download_queue, 'post', payload);
};

export const popDownloadQueue = (root, files) => {
  const payload = new FormData();
  payload.append('root', root);
  files.forEach(file => {
    payload.append('files[]', file);
  });

  return makeRequest(config.api.files.pop_download_queue, 'post', payload);
};

export const clearDownloadQueue = root => {
  const payload = new FormData();
  payload.append('root', root);
  return makeRequest(config.api.files.clear_download_queue);
};

export const createFolder = (root, name) => {
  return makeRequest(config.api.files.create_folder, 'post', { root, name });
};

export const createFolderV3 = (root, name) => {
  return makeRequest(config.api.filesV3.create_folder(root), 'post', { folder_name: name });
};

export const upload = async (root, files) => {
  const payload = new FormData();
  payload.append('root', root);
  payload.append('__ajax', true);

  files.forEach(file => {
    if (file.name === undefined || file.type === 'file') {
      return;
    }
    payload.append('file', file);
  });
  return makeRequest(config.api.files.upload, 'post', payload);
};

export const shareFileWith = () => {};

export const shareInfo = id => {
  return makeRequest(config.api.files.share_info(id), 'get');
};

export const shareFileEmails = (id, emails) => {
  const payload = {
    sharing_type: 'BY_EMAILS'
  };
  if (emails.length) {
    payload.emails = emails;
  }
  return makeRequest(config.api.files.share_file(id), 'post', payload);
};
export const shareFileEmail = async (id, email, until, actions) => {
  const payload = {
    sharing_type: 'BY_EMAIL',
    email,
    until,
    actions
  };
  return makeRequest(config.api.files.share_file(id), 'post', payload);
};
// eslint-disable-next-line
export const shareFileUser = async (id, user_id, until, actions) => {
  const payload = {
    sharing_type: 'BY_USER_ID',
    user_id,
    until,
    actions
  };
  return makeRequest(config.api.files.share_file(id), 'post', payload);
};

export const generateQRCode = async id => {
  const payload = {
    sharing_type: 'BY_QR'
  };
  return makeRequest(config.api.files.share_file(id), 'post', payload);
};

export const updateShared = async data => {
  const payload = { ...data };
  const { shareId, fileId } = payload;
  delete payload.shareId;
  delete payload.fileId;
  delete payload.type;
  return makeRequest(config.api.files.update_shared(fileId, shareId), 'put', payload);
};

export const updateSharedEmails = async (fileId, shareId, emails) => {
  const payload = {
    emails
  };
  return makeRequest(config.api.files.update_shared(fileId, shareId), 'patch', payload);
};

export const stopShareFile = async (fileId, shareId) => {
  return makeRequest(config.api.files.stop_share(fileId, shareId), 'delete');
};

export const setFacilityPhoto = async (siteId, fileId, type) => {
  const res = await makeRequest(config.api.files[`set_${type}`](siteId, fileId));
  if (typeof res === 'string') {
    return { msg: 'Server Error', isSuccess: false };
  }
  if (res.error) {
    return { msg: res.error, isSuccess: false };
  }
  return { msg: '', isSuccess: true };
};

export const downloadQueueAPI = async url => {
  const res = await axios.get(url, { responseType: 'blob' });
  return res;
};

export const generateUrlToShow = async (fileId, url, token) => {
  const base = `${apiURL(config.api.files.display_file(fileId))}`;
  try {
    const res = await axios(url || base, {
      method: 'GET',
      responseType: 'blob',
      cancelToken: token
    });
    const type = res.headers['content-type'].split(';')[0];
    const file = new Blob([res.data], { type });
    const fileURL = URL.createObjectURL(file);
    return fileURL;
  } catch (e) {
    if (e.message === 'refresh') {
      return 'retry';
    }
    return null;
  }
};

export const generateUrlToMobileShow = async (fileId, url) => {
  const base = `${apiURL(config.api.files.display_file(fileId))}`;
  try {
    const res = await axios(url || base, {
      method: 'GET'
    });
    // const type = res.headers['content-type'].split(';')[0];
    // const file = new Blob([res.data], { type });
    // const fileURL = URL.createObjectURL(file);
    // return fileURL;
    return res.data;
  } catch (e) {
    return null;
  }
};
