import ReactDOM from 'react-dom';
import { Suspense, lazy } from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import history from './helpers/history';
import ProtectedRoute from './components/ProtectedRoute';
import { PageProvider } from './layout/pageContext';
import { SidebarProvider } from './layout/sidebarContext';
import { QueryContextProvider } from './pages/workorders/wo-context';
import HomeRoute from './components/HomeRoute';
import { getUserData } from './_actions';

const Login = lazy(() => import('./pages/authentication/login'));
const Logout = lazy(() => import('./pages/authentication/logout'));
const ForgotPassword = lazy(() => import('./pages/authentication/forgotPassword'));
const ResetPassword = lazy(() => import('./pages/authentication/ResetPassword'));
const Register = lazy(() => import('./pages/authentication/registration'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const Notifications = lazy(() => import('./pages/notifications'));
const UpdateCaches = lazy(() => import('./pages/user/UpdateCaches'));
const IssueDetail = lazy(() => import('./pages/issues'));
const GroupDetail = lazy(() => import('./pages/issues/group'));
const Analytics = lazy(() => import('./pages/analytics'));
const SharedFilesHOC = lazy(() => import('./pages/sharedFiles'));
const LineItems = lazy(() => import('./pages/lineitems'));
const TestTracking = lazy(() => import('./pages/testtracking'));
const WorkOrders = lazy(() => import('./pages/workorders'));
const SingleWorkOrder = lazy(() => import('./pages/singleWorkorder'));
const PageNotFound = lazy(() => import('./pages/base/404'));
const ShowImage = lazy(() => import('./pages/base/ShowImage'));
const ShowXml = lazy(() => import('./pages/base/ShowXml'));
const PageNotFoundUnauthorise = lazy(() => import('./pages/base/404'));
const AllReports = lazy(() => import('./pages/reports/All'));
const RecapReport = lazy(() => import('./pages/reports/Recap'));
const PeriodicOverviewReport = lazy(() => import('./pages/reports/PeriodicOverviewReport'));
const RegulatorReport = lazy(() => import('./pages/reports/Regulator'));
const RemedyReport = lazy(() => import('./pages/reports/Remedy'));
const AnalyticsReport = lazy(() => import('./pages/reports/Analytics'));
const AnalyticsReportV2 = lazy(() => import('./pages/reports/Analytics'));
// const FacilityProfile = lazy(() => import('./pages/facilityProfile'));
const CathodicProtectionReport = lazy(() => import('./pages/reports/CathodicProtection'));
const HotTopicReport = lazy(() => import('./pages/reports/HotTopic'));
const WidgetsReport = lazy(() => import('./pages/reports/Widgets'));
const TermsOfService = lazy(() => import('./pages/terms'));
const Inspections = lazy(() => import('./pages/inspections'));
const ShowPdf = lazy(() => import('./pages/base/ShowPdf'));
const DeficienciesAnalytics = lazy(() => import('./pages/deficienciesAnalytics'));
const DownloadPDF = lazy(() => import('./pages/base/DownloadPDF'));
const ATGMonitoring = lazy(() => import('./pages/atgMonitoring'));
const ReleaseDetection = lazy(() => import('./pages/releaseDetection'));
const ATGSetupCenter = lazy(() => import('./pages/setupCenter'));
const ScoreCard = lazy(() => import('./pages/scoreCard'));
const DailyOverviewReport = lazy(() => import('./pages/reports/DailyOverviewReport'));
const ATGConnectionCenter = lazy(() => import('./pages/atgConnectionCenter'));
const ScoreCardReport = lazy(() => import('./pages/reports/ScoreCardReport'));
const PrivacyPolicy = lazy(() => import('./pages/policy'));
const AcquisitionReport = lazy(() => import('./pages/reports/AcquisitionReport'));
const DetailsPage = lazy(() => import('./pages/testtracking/DetailsPage'));
const CabinetV3 = lazy(() => import('./pages/cabinetV3'));
const Learning = lazy(() => import('./pages/learning'));
const AlarmsV2 = lazy(() => import('./pages/atgAlarmsV2'));
const PhotoResearch = lazy(() => import('./pages/PhotoResearch'));
const Calendar = lazy(() => import('./pages/calendar'));
const Vendors = lazy(() => import('./pages/vendors'));
const Map = lazy(() => import('./pages/map'));
const RoutingGantt = lazy(() => import('./pages/routingGantt'));
const GanttPage = lazy(() => import('./pages/ganttPage'));
const Inventory = lazy(() => import('./pages/inventory'));
const FacilityProfileNew = lazy(() => import('./pages/facilityProfile2'));
const MonthlyComparisonReport = lazy(() => import('./pages/reports/MonthlyComparisonReport'));
const ATGTerminal = lazy(() => import('./pages/atgTerminal'));
const InvoiceApproval = lazy(() => import('./pages/invoiceApproval'));
const JobMonitoring = lazy(() => import('./pages/jobs_monitoring'))

if (!window.origin.includes('local')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    environment: process.env.REACT_APP_SENTRY_ENV || 'NoEnv',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/proteanapp\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  const dataUser = getUserData();
  Sentry.setContext('ExtraData', {
    profile: dataUser?.current_user || 'NoUser',
    roles: dataUser?.roles || 'NoRoles'
  });
}

function at(n) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += this.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined;
  // Otherwise, this is just normal property access
  return this[n];
}

if (!Event.prototype.hasOwnProperty('path')) {
  Object.defineProperty(Event.prototype, 'path', {
    get() {
      return this.composedPath();
    }
  });
}

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
  Object.defineProperty(C.prototype, 'at', {
    value: at,
    writable: true,
    enumerable: false,
    configurable: true
  });
}

const App = () => {
  return (
    <Router history={history}>
      {/* <Suspense fallback={<Page documentName='Loading' path='Loading' pageName="loading"><div /></Page>}> */}
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/">
            {/* <Redirect to={{ pathname: '/dashboard' }} /> */}
            <HomeRoute />
          </Route>
          <ProtectedRoute
            exact
            visible="unauthorized"
            path="/login"
            component={Login}
            name="login"
          />
          <ProtectedRoute
            exact
            visible="unauthorized"
            path="/forgot-password"
            component={ForgotPassword}
            name="forgot_password"
          />
          <ProtectedRoute
            exact
            visible="unauthorized"
            path="/reset-password/:token"
            component={ResetPassword}
            name="reset_password"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/logout"
            component={Logout}
            name="logout"
          />
          <ProtectedRoute
            exact
            visible="unauthorized"
            path="/self-registration/:token"
            component={Register}
            name="register"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/dashboard"
            component={Dashboard}
            name="dashboard"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/notifications"
            component={Notifications}
            name="notifications"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/calendar"
            component={Calendar}
            name="dashboard"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/workorders"
            component={WorkOrders}
            name="workorders_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/workorders/:id"
            component={SingleWorkOrder}
            name="workorders_enabled"
            detail
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/lineitems"
            component={LineItems}
            name="workorders_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/invoice_approval"
            component={InvoiceApproval}
            name="invoice_approval_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/issues/:id"
            component={IssueDetail}
            name="issue_tracker_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/groups/:id"
            component={GroupDetail}
            name="issue_tracker_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/e-cabinet"
            component={CabinetV3}
            name="file_cabinet_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/e-cabinet/id/:fileId"
            component={CabinetV3}
            name="file_cabinet_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/e-cabinet/search/:query"
            component={CabinetV3}
            name="file_cabinet_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/inspections"
            component={Inspections}
            name="analytics_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/analytics"
            component={Analytics}
            name="analytics_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/tests"
            component={TestTracking}
            name="test_tracking_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/tests/:testId"
            component={DetailsPage}
            name="test_tracking_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/all"
            component={AllReports}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/analytics_report"
            component={AnalyticsReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/dev/reports/analytics_report"
            component={AnalyticsReportV2}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/daily_overview"
            component={DailyOverviewReport}
            name="daily_overview_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/periodic_overview"
            component={PeriodicOverviewReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/recap"
            component={RecapReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/remedy"
            component={RemedyReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/regulator"
            component={RegulatorReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/hot_topic"
            component={HotTopicReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/cathodic_protection"
            component={CathodicProtectionReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/widgets"
            component={WidgetsReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/monthly_comparison_report"
            component={MonthlyComparisonReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/reports/acquisition"
            component={AcquisitionReport}
            name="reports_enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/facility_profile/:id"
            component={FacilityProfileNew}
            name="facility_profile"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/remote_monitoring"
            component={ATGMonitoring}
            name="atg"
            feature="atg.enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/release_detection"
            component={ReleaseDetection}
            name="atg"
            feature="atg.enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/setup_center"
            component={ATGSetupCenter}
            name="atg"
            feature="atg.setup_center.access"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/alarms"
            component={AlarmsV2}
            name="atg"
            feature="atg.enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/connection_center"
            component={ATGConnectionCenter}
            name="atg"
            feature="atg.enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/atg_terminal"
            component={ATGTerminal}
            name="atg"
            feature="atg.enabled"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/inventory_management"
            component={Inventory}
            name="inventory_management_enabled"
            feature="inventory_management.access"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/image/:imageId"
            component={ShowImage}
            name="show_image"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/xml/:fileId"
            component={ShowXml}
            name="show_xml"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/pdf/:fileId"
            component={ShowPdf}
            name="show_pdf"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/download_report/:reportId/"
            component={DownloadPDF}
            name="show_pdf"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/performance"
            component={ScoreCard}
            name="show_pdf"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/deficiency"
            component={ScoreCard}
            name="show_pdf"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/deficiency_specifics"
            component={ScoreCard}
            name="show_pdf"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/scorecard_report"
            component={ScoreCardReport}
            name="show_pdf"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/max"
            component={DeficienciesAnalytics}
            name="quick_dispatch_enabled"
            feature={'quick_dispatch.read'}
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/vendors"
            component={Vendors}
            name="quick_dispatch_enabled"
            feature="vendor.read"
          />
          {/* <ProtectedRoute
            exact
            visible="protected"
            path="/routing"
            component={RoutingGantt}
            name="show_pdf"
          /> */}
          {/* <ProtectedRoute
            exact
            visible="protected"
            path="/routing2"
            component={GanttPage}
            name="show_pdf"
          /> */}
          <ProtectedRoute
            exact
            visible="protected"
            path="/404"
            component={PageNotFound}
            name="404"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/photoResearch"
            component={PhotoResearch}
            name="photoResearch"
          />
          <ProtectedRoute exact visible="protected" path="/map" component={Map} name="show_pdf" />
          <ProtectedRoute
            exact
            visible="protected"
            path="/update_caches"
            component={UpdateCaches}
            name="dashboard"
          />
          <ProtectedRoute
            exact
            visible="protected"
            path="/jobs"
            component={JobMonitoring}
            name="dashboard"
          />

          <Route
            exact
            path="/e-cabinet/share/:fileId/:subId"
            component={SharedFilesHOC}
            name="share"
          />
          <Route exact path="/e-cabinet/share/:fileId" component={SharedFilesHOC} name="share" />
          <Route exect path="/learning" component={Learning} name="share" />
          <Route exact path="/terms_of_service" component={TermsOfService} name="terms_service" />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} name="terms_service" />
          <Route component={PageNotFoundUnauthorise} />
        </Switch>
      </Suspense>
    </Router>
  );
};

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut'
});

ReactDOM.render(
  <PageProvider>
    <SidebarProvider>
      <QueryContextProvider>
        <ToastContainer
          className="notification-toast"
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
        <App />
      </QueryContextProvider>
    </SidebarProvider>
  </PageProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
