/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import { createContext, useState } from 'react';
import io from 'socket.io-client';
import config from '../_config/config';

const initialContext = {
  loading: false,
  alerts: [],
  comments: [],
  config: {},
  loadingConfig: false,
  firstRender: true,
  isOpen: false,

  notificationSocket: io(`${config.ws.ws_url}/notifications`, {
    transports: ['websocket'],
    autoConnect: false
  }),

  reportsSocket: io(`${config.ws.ws_url}/reports`, {
    transports: ['websocket'],
    autoConnect: false
  }),

  atgSocket: io(`${config.ws.ws_url}/atg`, {
    transports: ['websocket'],
    autoConnect: false
  }),

  permitSocket: io(`${config.ws.ws_url}/permits`, {
    transports: ['websocket'],
    autoConnect: false
  }),

  complianceTestingSocket: io(`${config.ws.ws_url}/compliance-testing`, {
    transports: ['websocket'],
    autoConnect: false
  }),
  workorderSocket: io(`${config.ws.ws_url}/workorders`, {
    transports: ['websocket'],
    autoConnect: false
  })
};

const PageContext = createContext([{}, () => {}]);

const PageProvider = props => {
  const [state, setState] = useState(initialContext);
  return <PageContext.Provider value={[state, setState]}>{props.children}</PageContext.Provider>;
};

export { PageContext, PageProvider };
