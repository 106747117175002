import { Redirect } from 'react-router-dom';
import { getUserData } from '../_actions';
import { getFirstEntry } from '../helpers/utils';

const HomeRoute = () => {
  const user = getUserData();
  console.log(getFirstEntry());

  if (!user.logged_in) {
    const newPath = getFirstEntry();
    return <Redirect to={newPath} />;
  }
  return <Redirect to={getFirstEntry()} />;
};

export default HomeRoute;
